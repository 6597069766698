import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDl1Zz3PkAysFUkvxizw79qr77BOn2Cnd4",
  authDomain: "blog-55c34.firebaseapp.com",
  projectId: "blog-55c34",
  storageBucket: "blog-55c34.appspot.com",
  messagingSenderId: "1009626022031",
  appId: "1:1009626022031:web:93eb7a41b2d1acb2b430fa",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();

// Timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, projectAuth, projectStorage, timestamp };

<template>
  <div class="navbar">
    <nav>
      <router-link class="nav-logo" to="/">
        <img src="@/assets/logo.png" alt="Logo" />
      </router-link>

      <div class="nav-right">
        <div class="user-info" v-if="user">
          <router-link
            class="user-info"
            :to="{ name: 'UserProfile', params: { id: user.uid } }"
          >
            <img
              :src="user.photoURL"
              alt="profile-picture"
              class="img-placeholder"
            />
            <div class="displayName">
              {{ user.displayName }}
            </div>
          </router-link>
        </div>
        <router-link class="link-button" to="/login" v-if="!user"
          ><button>Login</button></router-link
        >
        <router-link class="link-button" to="/signup" v-if="!user"
          ><button>Signup</button></router-link
        >
        <button @click="handleLogout" v-if="user">Logout</button>
      </div>
    </nav>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import useLogout from '@/utils/useLogout';
import getUser from '@/utils/getUser';

export default {
  setup() {
    const { user } = getUser();
    const { logout } = useLogout();
    const router = useRouter();

    const handleLogout = async () => {
      await logout();
      router.push('/');
    };
    return { handleLogout, getUser, user };
  },
};
</script>
<style scoped>
.navbar {
  background-color: transparent;
  width: 100%;
  box-shadow: 0 1px 3px var(--grey);
  margin-bottom: 3rem;
}
nav {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}
.nav-logo {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.nav-logo span {
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 700;
}
.nav-logo a {
  background-color: transparent;
}
.nav-logo img {
  background-color: transparent;
  height: 60px;
  width: 60px;
  padding: 0.5rem;
}

.nav-right {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.user-info {
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.img-placeholder {
  background-color: var(--background);
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.displayName {
  background-color: transparent;
}
.link-button {
  background-color: transparent;
}
</style>

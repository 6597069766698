import { createRouter, createWebHistory } from "vue-router";

// route guard
import { projectAuth } from "@/firebase/config";
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    props: true,
  },
  {
    path: "/user/:id",
    name: "UserProfile",
    component: () => import("../views/profile/ProfileView.vue"),
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/LoginView.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/auth/SignupView.vue"),
  },
  {
    path: "/posts/:id",
    name: "PostDetails",
    component: () => import("../views/posts/PostDetailsView.vue"),
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: "/chatroom",
    name: "Chatroom",
    component: () => import("@/views/chatroom/ChatroomView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <div class="app">
    <Navbar :key="$route.fullPath" />
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import Navbar from "./components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
};
</script>
<style scoped>
.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
</style>
